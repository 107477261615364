import {
  createNewAlert,
  deleteAlert,
  getAlertActions,
  getAlertMeasures,
  getAllAlertsCsv,
  getBundleAlertsAccount,
  getBundleAlertsIndividual,
  getBundleAlertGlobal,
  getProductTypes,
  getUsageAlertById,
  getUsageAlerts,
  getUsageAlertsByAsset,
  getUsageAlertsHistory,
  updateBulkUsageAlerts,
  updateBundleAlertsAccounts,
  updateBundleAlertsAccountsDisableAll,
  updateBundleAlertsAccountsEnableAll,
  updateBundleAlertsIndividuals,
  updateBundleAlertsIndividualsDisableAll,
  updateBundleAlertsindividualsEnableAll,
  updateUsageAlert
} from "./api";
import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from "react-query";
import { AjaxResponse, IdValueQuery } from "../_app/api";
import {
  AlertMeasure,
  BarAlertAction,
  BillingAlertBulkEdit,
  BundleAlertUpdate,
  CreateUsageAlert,
  GetBundleAlertsAccountResponse,
  GetBundleAlertsIndividualResponse,
  GetUsageAlertsHistoryResponse,
  GetUsageAlertsResponse,
  ProductType,
  UpdateUsageAlert,
  UsageAlert,
} from "./types";
import { useFeedbackAlerts } from "../_app/hooks";
import { alertRequestQueuedMessage, genericError } from "../_app/utils/text";
import { Query } from "../filter/types";
import { useHistory } from "react-router-dom";

export function useUsageAlerts(limit: number, queries: any[], options = {}) {
  const { setFeedbackAlertError } = useFeedbackAlerts();

  return useInfiniteQuery<GetUsageAlertsResponse, AjaxResponse>(
    ["usageAlerts", { limit, queries }],
    ({ pageParam }) => getUsageAlerts(pageParam, limit, queries),
    {
      onError: (error: any) => {
        setFeedbackAlertError(error?.data?.message || genericError());
      },
      getNextPageParam: (lastPage: any) => {
        return lastPage?.nextPageToken || undefined;
      },
      ...options,
    },
  );
}

export function useUsageAlertsHistory(limit: number, queries: Query[], options = {}) {
  const { setFeedbackAlertError } = useFeedbackAlerts();

  return useInfiniteQuery<GetUsageAlertsHistoryResponse, AjaxResponse>(
    ["usageAlertsHistory", { limit, queries }],
    ({ pageParam }) => getUsageAlertsHistory(pageParam, limit, queries),
    {
      onError: (error: any) => {
        setFeedbackAlertError(error?.data?.message || genericError());
      },
      getNextPageParam: (lastPage: any) => {
        return lastPage?.nextPageToken || undefined;
      },
      ...options,
    },
  );
}

export function useSingleUsageAlert(id: string, options = {}) {
  return useQuery<UsageAlert, AjaxResponse>(["usageAlert", id], () => getUsageAlertById(id), {
    ...options,
  });
}

export function useAssetUsageAlerts(assetId: string, limit: number, queries: any[], options = {}) {
  const { setFeedbackAlertError } = useFeedbackAlerts();

  return useInfiniteQuery<GetUsageAlertsResponse, AjaxResponse>(
    ["usageAlertsByAssetId", { assetId, limit, queries }],
    ({ pageParam }) => getUsageAlertsByAsset(assetId, pageParam, limit, queries),
    {
      onError: (error: any) => {
        setFeedbackAlertError(error?.data?.message || genericError());
      },
      getNextPageParam: (lastPage: any) => {
        return lastPage?.nextPageToken || undefined;
      },
      ...options,
    },
  );
}

export function useProductTypes(options = {}) {
  return useQuery<ProductType[], AjaxResponse>(["usageAlertProductTypes"], () => getProductTypes(), {
    ...options,
  });
}

export function useAlertActions(id: string, options = {}) {
  return useQuery<BarAlertAction[], AjaxResponse>(["usageAlertActions", id], () => getAlertActions(id), {
    ...options,
  });
}

export function useAlertMeasures(product: string, options = {}) {
  return useQuery<AlertMeasure[], AjaxResponse>(["usageAlertMeasures", product], () => getAlertMeasures(product), {
    ...options,
  });
}

export function useCreateNewAlert(options: any = {}) {
  const queryClient = useQueryClient();
  const { setFeedbackAlertError, setFeedbackAlertSuccess } = useFeedbackAlerts();

  return useMutation((alert: CreateUsageAlert) => createNewAlert(alert), {
    ...options,
    onSuccess: () => {
      queryClient.invalidateQueries(["usageAlerts"]);
      queryClient.invalidateQueries(["contacts"]);
      setFeedbackAlertSuccess(alertRequestQueuedMessage);
      options?.onSuccess?.();
    },
    onError: (error: any) => {
      setFeedbackAlertError(error?.data?.message);
    },
  });
}

export function useUpdateUsageAlert(id: string, options: any = {}) {
  const queryClient = useQueryClient();
  const { setFeedbackAlertError, setFeedbackAlertSuccess } = useFeedbackAlerts();

  return useMutation<string, string[], UpdateUsageAlert>((usageAlert: UpdateUsageAlert) => updateUsageAlert(usageAlert), {
    ...options,
    onSuccess: () => {
      queryClient.invalidateQueries(["usageAlerts"]);
      queryClient.invalidateQueries(["usageAlert", id]);
      queryClient.invalidateQueries(["contacts"]);
      setFeedbackAlertSuccess(alertRequestQueuedMessage);
      options?.onSuccess?.();
    },
    onError: (error: any) => {
      setFeedbackAlertError(error?.data?.message);
    },
  });
}

export function useDeleteAlerts(options = {}) {
  const queryClient = useQueryClient();
  const { setFeedbackAlertError, setFeedbackAlertSuccess } = useFeedbackAlerts();

  return useMutation((alert: string[]) => deleteAlert(alert), {
    ...options,
    onSuccess: () => {
      queryClient.invalidateQueries(["usageAlerts"]);
      queryClient.invalidateQueries(["contacts"]);
      setFeedbackAlertSuccess(alertRequestQueuedMessage);
    },
    onError: (error: AjaxResponse) => {
      setFeedbackAlertError(error?.data?.message || genericError());
    },
  });
}

export function useBulkEditAlerts(options = {}) {
  const { setFeedbackAlertError, setFeedbackAlertSuccess } = useFeedbackAlerts();
  const queryClient = useQueryClient();
  const history = useHistory();

  return useMutation((bulkUpdate: BillingAlertBulkEdit) => updateBulkUsageAlerts(bulkUpdate), {
    ...options,
    onSuccess: () => {
      setFeedbackAlertSuccess(alertRequestQueuedMessage);
      queryClient.invalidateQueries(["usageAlerts"]);
      queryClient.invalidateQueries(["contacts"]);
      history.push("/usage-alerts");
    },
    onError: (error: AjaxResponse) => {
      setFeedbackAlertError(error?.data?.message || genericError());
    },
  });
}

/* USAGE ALERTS END */

/* BUNDLE ALERTS START */

export function useBundleAlertsIndividual(page: number, limit: number, queries?: IdValueQuery[], options = {}) {
  const { setFeedbackAlertError } = useFeedbackAlerts();

  return useQuery<GetBundleAlertsIndividualResponse, AjaxResponse>(
    ["bundleAlertsIndividual", { page, limit, queries }],
    () => getBundleAlertsIndividual(page, limit, queries),
    {
      ...options,
      onError: () => {
        setFeedbackAlertError(genericError());
      },
    },
  );
}

export function useBundleAlertsAccounts(page: number, limit: number, options = {}) {
  return useQuery<GetBundleAlertsAccountResponse, AjaxResponse>(
    ["bundleAlertsAccount", { page, limit }],
    () => getBundleAlertsAccount(page, limit),
    {
      ...options,
    },
  );
}

export function useBundleAlertGlobal() {
  const { setFeedbackAlertError } = useFeedbackAlerts();

  return useQuery<Boolean, AjaxResponse>(
    ["bundleAlertGlobal"],
    () => getBundleAlertGlobal(),
    {
      onError: () => {
        setFeedbackAlertError(genericError());
      },
    }
  );
}

function useUpdateBundleAlertsInternal(update: (bundleAlerts: BundleAlertUpdate[]) => Promise<string>, options: any) {
  const queryClient = useQueryClient();
  return useMutation<string, AjaxResponse, BundleAlertUpdate[]>((bundleAlerts) => update(bundleAlerts), {
    ...options,
    onSuccess: (data: string) => {
      queryClient.invalidateQueries(["contacts"]);
      options?.onSuccess?.(data);
    },
    onError: (data: any) => {
      options?.onError?.(data);
    },
  });
}

export function useUpdateBundleAlertsAccounts(options?: any) {
  return useUpdateBundleAlertsInternal(updateBundleAlertsAccounts, options);
}

export function useUpdateBundleAlertsIndividuals(options?: any) {
  return useUpdateBundleAlertsInternal(updateBundleAlertsIndividuals, options);
}

function useUpdateBundleAlertsAccountsInternal(update: () => Promise<string>, options: any) {
  const queryClient = useQueryClient();
  return useMutation<string>(() => update(), {
    ...options,
    onSuccess: (data: string) => {
      queryClient.invalidateQueries(["contacts"]);
      options?.onSuccess?.(data);
    },
    onError: (data: any) => {
      options?.onError?.(data);
    },
  });
}

export function useUpdateBundleAlertsAccountsEnableAll(options?: any) {
  return useUpdateBundleAlertsAccountsInternal(updateBundleAlertsAccountsEnableAll, options);
}

export function useUpdateBundleAlertsAccountsDisableAll(options?: any) {
  return useUpdateBundleAlertsAccountsInternal(updateBundleAlertsAccountsDisableAll, options);
}

function useUpdateBundleAlertsIndividualsInternal(
  update: (filters?: IdValueQuery[] | undefined) => Promise<string>,
  options: any,
) {
  const queryClient = useQueryClient();
  return useMutation<string, AjaxResponse, IdValueQuery[] | undefined>((filters) => update(filters), {
    ...options,
    onSuccess: (data: string) => {
      queryClient.invalidateQueries(["contacts"]);
      options?.onSuccess?.(data);
    },
    onError: (data: any) => {
      options?.onError?.(data);
    },
  });
}

export function useUpdateBundleAlertsIndividualsEnableAll(options?: any) {
  return useUpdateBundleAlertsIndividualsInternal(updateBundleAlertsindividualsEnableAll, options);
}

export function useUpdateBundleAlertsIndividualsDisableAll(options?: any) {
  return useUpdateBundleAlertsIndividualsInternal(updateBundleAlertsIndividualsDisableAll, options);
}

export function useExportAllAlerts(options: any = {}) {
  const { setFeedbackAlertError } = useFeedbackAlerts();

  return useQuery(["all-alerts-export"], () => getAllAlertsCsv(), {
    enabled: false,
    cacheTime: 0,
    onError: () => {
      setFeedbackAlertError("Could not export alerts.");
    },
    ...options,
  });
}
