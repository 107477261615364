import { Controller, useFormContext } from "react-hook-form";
import SelectDropdown, { Option } from "../../../form/components/SelectDropdown";
import React from "react";
import { useAccountsForLevel } from "../../hooks";
import { getAccountOption } from "../../utils";
import SelectedAccountsChips from "../SelectedAccountsChips";
import { useAccountContext } from "../../../_app/providers/AccountHierarchyProvider";
import { AccountLevel } from "../../../account/utils";

interface Props {
  classes: any;
}

const SubAccountSelector = ({ classes }: Props) => {
  const {
    control,
    watch,
    setValue,
    formState: { errors },
  } = useFormContext();

  const selectedAccountIds = watch("accounts", []);
  const parentAccount = watch("parentAccount");

  const [searchTerm, setSearchTerm] = React.useState<string | undefined>();
  const [parentAccountSearchTerm, setParentAccountQuery] = React.useState<string | undefined>();

  const {
    data: parentAccounts,
    fetchNextPage: fetchParentAccountNextPage,
    hasNextPage: parentAccountNextPage,
    isFetching: isParentAccountFetching,
  } = useAccountsForLevel(`${AccountLevel.Account.id}`, { "search-term": parentAccountSearchTerm });

  const { data, fetchNextPage, hasNextPage, isFetching } = useAccountsForLevel(`${AccountLevel.SubAccount.id}`, {
    "search-term": searchTerm,
    parent: parentAccount,
  });

  const { currentAccountLevel } = useAccountContext();

  const accountsList: Option[] = data?.pages.flatMap((page) => page.list.map((account: any) => getAccountOption(account))) || [];
  const parentAccountList: Option[] =
    parentAccounts?.pages.flatMap((page) => page.list.map((account: any) => getAccountOption(account))) || [];
  const selectedAccounts = accountsList.filter((account) => selectedAccountIds.includes(account.value));

  const isAccountLevel = currentAccountLevel === AccountLevel.Account;
  const showSubAccounts = parentAccount || currentAccountLevel === AccountLevel.SubAccount;

  return (
    <>
      {isAccountLevel && (
        <Controller
          name="parentAccount"
          control={control}
          render={({ field }) => (
            <SelectDropdown
              {...field}
              label={"Account"}
              data={parentAccountList}
              isFetching={isParentAccountFetching}
              searchOn={(q: any) => {
                setParentAccountQuery(q || undefined);
              }}
              searchList={parentAccountList}
              ctrClass={classes.textField}
              onChange={(e: any) => {
                setValue("parentAccount", e.target.value);
                setValue("accounts", []);
              }}
              nextPage={fetchParentAccountNextPage}
              hasNextPage={parentAccountNextPage}
              multiline
              infiniteScroll
              hasSearch
              hasNoOptionsText
            />
          )}
        />
      )}
      {showSubAccounts && (
        <Controller
          name="accounts"
          control={control}
          render={({ field }) => (
            <SelectDropdown
              {...field}
              label="Sub Accounts"
              data={accountsList}
              searchOn={(q: any) => {
                setSearchTerm(q || undefined);
              }}
              ctrClass={classes.textField}
              nextPage={fetchNextPage}
              hasNextPage={hasNextPage}
              multiline
              infiniteScroll
              multiple={true}
              isFetching={isFetching}
              hasSearch
              hasNoOptionsText
              error={!!errors?.accounts}
              helperText={errors?.accounts?.message as string | undefined}
            />
          )}
        />
      )}
      <SelectedAccountsChips selectedAccounts={selectedAccounts} />
    </>
  );
};

export default SubAccountSelector;
