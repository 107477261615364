import React from "react";
import Grid from "@mui/material/Grid";
import { Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import UIButton from "../../_app/components/UIButton";

export interface Props {
  onSubmit?: (...args: any[]) => void;
  onCancel?: (...args: any[]) => void;
  isSubmitting?: boolean;
  submitLabel?: string | boolean;
  cancelLabel?: string | boolean;
  disableSubmit?: boolean;
}

export const SubmitButtons = ({
  onSubmit = () => null,
  onCancel = () => null,
  isSubmitting = false,
  submitLabel = "Submit",
  cancelLabel = "Cancel",
  disableSubmit = false,
}: Props) => {
  const classes = useStyles();
  return (
    <Grid container direction="row" justifyContent="flex-end" alignItems="center">
      {Boolean(cancelLabel) && (
        <UIButton
          className={classes.cancelButton}
          variant="outlined"
          color="primary"
          onClick={onCancel}
          data-cy="edit-form-cancel"
        >
          {cancelLabel}
        </UIButton>
      )}
      {Boolean(submitLabel) && (
        <UIButton
          className={classes.submitButton}
          variant="contained"
          color="primary"
          onClick={onSubmit}
          isLoading={isSubmitting}
          data-cy="edit-form-submit"
          disabled={disableSubmit}
        >
          {submitLabel}
        </UIButton>
      )}
    </Grid>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cancelButton: {
      marginTop: theme.spacing(4),
      marginLeft: theme.spacing(2),
      padding: "7px 18px",
    },
    submitButton: {
      marginTop: theme.spacing(4),
      marginLeft: theme.spacing(2),
    },
  }),
);

export default SubmitButtons;
