import SelectDropdown from "../../../form/components/SelectDropdown";
import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useAllRolesByAccountId } from "../../../role/hooks";
import Grid from "@mui/material/Grid";

interface Props {
  classes?: any;
}

const RoleSelector = ({ classes }: Props) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const { data } = useAllRolesByAccountId();

  const roles =
    data?.list?.map((role) => ({
      id: role.id,
      label: role.name,
      value: role.id,
    })) || [];

  if (!roles.length) return null;

  const fullList = [{ id: "", label: "No Role", value: "" }, ...roles];

  return (
    <>
      <Grid item xs={12} md={4}></Grid>
      <Grid item xs={12} md={8}>
        <Controller
          name="role"
          control={control}
          render={({ field }) => (
            <SelectDropdown
              {...field}
              label="Role"
              data={fullList}
              ctrClass={classes.textField}
              error={!!errors?.role}
              helperText={errors?.role?.message as string | undefined}
            />
          )}
        />
      </Grid>
    </>
  );
};

export default RoleSelector;
