import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import React from "react";
import SelectDropdown from "../../form/components/SelectDropdown";
import AccountSelector from "./selectors/AccountSelector";
import SubAccountSelector from "./selectors/SubAccountSelector";
import { useAccountContext } from "../../_app/providers/AccountHierarchyProvider";
import { AccountLevel } from "../../account/utils";

interface Props {
  classes?: any;
}

const userLevelList = [
  { label: AccountLevel.Account.label, value: AccountLevel.Account.id, id: AccountLevel.Account.id },
  { label: AccountLevel.SubAccount.label, value: AccountLevel.SubAccount.id, id: AccountLevel.SubAccount.id },
];

const EditAccountDetailsForm = ({ classes }: Props) => {
  const {
    control,
    setValue,
    formState: { errors },
  } = useFormContext();

  const { currentAccountLevel } = useAccountContext();

  const selectedLevel = useWatch({ control, name: "userLevel" });

  return (
    <>
      <Grid item xs={12} md={4}>
        <Typography variant="h2" gutterBottom>
          Account details
        </Typography>
      </Grid>
      <Grid item xs={12} md={8}>
        <Controller
          name="userLevel"
          control={control}
          render={({ field }) => (
            <SelectDropdown
              disabled={currentAccountLevel?.id !== AccountLevel.Account.id}
              {...field}
              onChange={(e: React.ChangeEvent<{ value: unknown }>) => {
                field.onChange(e);
                setValue("accounts", []);
              }}
              label="User level"
              data={userLevelList}
              ctrClass={classes.textField}
              error={!!errors?.userLevel}
              helperText={errors?.userLevel?.message as string | undefined}
            />
          )}
        />

        {selectedLevel === AccountLevel.Account.id && <AccountSelector classes={classes} />}
        {selectedLevel === AccountLevel.SubAccount.id && <SubAccountSelector classes={classes} />}
      </Grid>
    </>
  );
};

export default EditAccountDetailsForm;
