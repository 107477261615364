import React, { useCallback, useEffect } from "react";
import { Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useFeedbackAlerts, useMemoizedCallback, useStore } from "../../_app/hooks";
import actions from "../../_app/store/Actions";
import { useQueryClient } from "react-query";
import { useHistory, useLocation } from "react-router-dom";
import { useAccountsForParent } from "../../account/hooks";
import { findMatchingById } from "../../_app/utils";
import { ContextHierarchy } from "../../_app/providers/HierarchyContextProvider";
import { deleteLogicMonitorToken } from "../../logic-monitor/api";
import SelectDropdown from "../../form/components/SelectDropdown";
import { Account } from "../../account/types";
import { getLastParent } from "../../context/utils";
import { useUserData } from "../../user/hooks";
import { useIdentityUser } from "../../auth/hooks";
import { useAccountContext } from "../../_app/providers/AccountHierarchyProvider";

interface SwitchAccountsDialogProps {
  title: string;
  open: boolean;
  setOpen: Function;
}

const SwitchAccountsDialog = ({ title, open, setOpen }: SwitchAccountsDialogProps) => {
  const classes = useStyles();
  const { refreshAccountHierarchy } = useAccountContext();
  const history = useHistory();
  const location = useLocation();
  const { dispatch, state } = useStore();
  const { setFeedbackAlertSuccess } = useFeedbackAlerts();
  const currentContext = state.contextHierarchy;
  const { data: identityUser } = useIdentityUser();
  const { userLevel, userAccounts } = useUserData();
  const queryClient = useQueryClient();
  const PAGE_LIMIT = 75;
  const PAGES_TO_REDIRECT = ["/usage", "/services"];

  const constructList = useMemoizedCallback((pages?: any[]) => {
    return pages?.reduce((acc, page) => [...acc, ...page?.list?.map(parseListItem)], []);
  }, []);

  const parseListItem = (item: Account) => ({
    value: item,
    label: `${item?.code} - ${item?.name}`,
    ...item,
  });

  const userAccountList = userAccounts.map(parseListItem) as any[];
  const [parentAccountList, setParentAccountList] = React.useState<any[]>([]);
  const [childAccountList, setChildAccountList] = React.useState<any[]>([]);
  const [grandChildAccountList, setGrandChildAccountList] = React.useState<any[]>([]);

  const [parentAccountQuery, setParentAccountQuery] = React.useState<any>(undefined);
  const [childAccountQuery, setChildAccountQuery] = React.useState<any>(undefined);
  const [grandChildAccountQuery, setGrandChildAccountQuery] = React.useState<any>(undefined);

  const [selectedParentAccount, setParentAccount] = React.useState<any>(undefined);
  const [selectedChildAccount, setChildAccount] = React.useState<any>(undefined);
  const [selectedGrandChildAccount, setGrandChildAccount] = React.useState<any>(undefined);

  const canSetUserAccount = Boolean(userAccountList?.length > 1);

  const canSetParentAccount = Boolean(
    identityUser && !selectedParentAccount ? true : userLevel < selectedParentAccount?.level?.id,
  );

  const canSetChildAccount = Boolean(selectedParentAccount?.id && (!childAccountList?.length ? childAccountQuery : true));
  const canSetGrandChildAccount = Boolean(
    selectedChildAccount?.id && (!grandChildAccountList?.length ? grandChildAccountQuery : true),
  );

  /* Fetch data */

  const {
    data: parentAccountResp,
    fetchNextPage: fetchNextParentAccount,
    hasNextPage: hasNextParentAccount,
  } = useAccountsForParent(
    {
      parent: canSetUserAccount ? selectedParentAccount?.id : userAccounts[0]?.id,
      limit: PAGE_LIMIT,
      "search-query": parentAccountQuery,
    },
    {
      enabled: Boolean(canSetUserAccount ? selectedParentAccount?.id : userAccounts[0]?.id),
      cacheTime: 0,
    },
  );

  const {
    data: childAccountResp,
    fetchNextPage: fetchNextChildAccount,
    hasNextPage: hasNextChildAccount,
  } = useAccountsForParent(
    {
      parent: selectedParentAccount?.id,
      limit: PAGE_LIMIT,
      "search-query": childAccountQuery,
    },
    { enabled: Boolean(selectedParentAccount?.id), cacheTime: 0 },
  );

  const {
    data: grandChildAccountResp,
    fetchNextPage: fetchNextGrandChild,
    hasNextPage: hasNextGrandChild,
  } = useAccountsForParent(
    {
      parent: selectedChildAccount?.id,
      limit: PAGE_LIMIT,
      "search-query": grandChildAccountQuery,
    },
    { enabled: Boolean(selectedChildAccount?.id), cacheTime: 0 },
  );

  /* Set selection options */

  const injectCachedDefault = useMemoizedCallback((list: any[], item: any) => {
    const match = findMatchingById(item?.id, list);
    if (item?.id && !match) {
      list?.push(parseListItem(item));
    }
    return list;
  }, []);

  useEffect(() => {
    const list = injectCachedDefault(constructList(parentAccountResp?.pages), selectedParentAccount);
    setParentAccountList(list);
  }, [constructList, injectCachedDefault, parentAccountResp, selectedParentAccount]);

  useEffect(() => {
    const list = injectCachedDefault(constructList(childAccountResp?.pages), selectedChildAccount);
    setChildAccountList(list);
  }, [childAccountResp, constructList, injectCachedDefault, selectedChildAccount]);

  useEffect(() => {
    const list = injectCachedDefault(constructList(grandChildAccountResp?.pages), selectedGrandChildAccount);
    setGrandChildAccountList(list);
  }, [constructList, grandChildAccountResp, injectCachedDefault, selectedGrandChildAccount]);

  /* Set defaults from cached context */

  const setDefaults = useCallback(() => {
    let parent, child, grand;
    if (currentContext?.id !== currentContext?.lastParentId) {
      grand = currentContext;
    }
    if (currentContext?.parentContext?.id !== currentContext?.lastParentId) {
      child = currentContext?.parentContext;
    }
    if (currentContext?.parentContext?.parentContext?.id !== currentContext?.lastParentId) {
      parent = currentContext?.parentContext?.parentContext;
    }
    if (!parent && child && grand) {
      parent = child;
      child = grand;
      grand = undefined;
    }
    if (!parent && !child && grand) {
      parent = grand;
      child = undefined;
      grand = undefined;
    }
    if (!parent && child && !grand) {
      parent = child;
      child = undefined;
      grand = undefined;
    }
    if (parent && !child && grand) {
      child = grand;
      grand = undefined;
    }
    if (parent) setParentAccount(parent);
    if (child) setChildAccount(child);
    if (grand) setGrandChildAccount(grand);
  }, [currentContext]);

  /* Handle selection changes */

  const onInputChange = (e: any) => {
    const key = e.target.name;
    const val = e.target.value;
    if (!val?.id) return;
    if (key === "parentAccount" || key === "userAccount") {
      setParentAccount(val);
      setChildAccount(undefined);
      setGrandChildAccount(undefined);
    }
    if (key === "childAccount") {
      setChildAccount(val);
      setGrandChildAccount(undefined);
    }
    if (key === "grandChildAccount") {
      setGrandChildAccount(val);
    }
  };

  const handleSwitchButtonClick = () => {
    let context: Partial<ContextHierarchy> | null = state.contextHierarchy || {};
    const userId = context?.userId;
    const lastParent = getLastParent(context) || {};

    if (!Boolean(selectedParentAccount?.id)) {
      if (context?.id !== selectedParentAccount?.id) {
        context = {
          ...lastParent,
          lastParentId: lastParent?.id || "",
        };
      }
    }
    if (!Boolean(selectedGrandChildAccount?.id) && !Boolean(selectedChildAccount?.id) && Boolean(selectedParentAccount?.id)) {
      if (context?.id !== selectedParentAccount?.id) {
        context = {
          ...selectedParentAccount,
          parentContext: {
            ...lastParent,
          },
          lastParentId: lastParent?.id,
        };
      }
    }
    if (!Boolean(selectedGrandChildAccount?.id) && Boolean(selectedChildAccount?.id) && Boolean(selectedParentAccount?.id)) {
      if (context?.id !== selectedChildAccount?.id) {
        context = {
          ...selectedChildAccount,
          parentContext: {
            ...selectedParentAccount,
            parentContext: {
              ...lastParent,
            },
          },
          lastParentId: lastParent?.id,
        };
      }
    }
    if (Boolean(selectedGrandChildAccount?.id) && Boolean(selectedChildAccount?.id) && Boolean(selectedParentAccount?.id)) {
      if (context?.id !== selectedGrandChildAccount?.id) {
        context = {
          ...selectedGrandChildAccount,
          parentContext: {
            ...selectedChildAccount,
            parentContext: {
              ...selectedParentAccount,
              parentContext: {
                ...lastParent,
              },
            },
          },
          lastParentId: lastParent?.id,
        };
      }
    }
    if (context) context.userId = userId;
    dispatch({ type: actions.SET_CONTEXT_HIERARCHY, payload: context });
    // Refresh new account hierarchy context as we have to support currently both context hierarchies
    if (context?.id) {
      refreshAccountHierarchy(context?.id);
    }
    setFeedbackAlertSuccess("Switch successful");
    // if switching context on /usage or /services, redirect to bills to choose correct bill period
    if (PAGES_TO_REDIRECT.includes(location.pathname)) {
      history.push("/bills");
    }
    deleteLogicMonitorToken();
    reset(true);
  };

  const reset = useMemoizedCallback(
    (close: boolean = false) => {
      setGrandChildAccount(undefined);
      setChildAccount(undefined);
      if (canSetParentAccount) setParentAccount(undefined);
      if (close) {
        setOpen(false);
        queryClient?.invalidateQueries({
          predicate: (query) => {
            const blacklist = ["identityUser", "myUser"];
            const key: string = String(query.queryKey[0]);
            return !blacklist.includes(key);
          },
        });
      }
    },
    [canSetParentAccount, queryClient, selectedChildAccount, selectedGrandChildAccount, selectedParentAccount, setOpen],
  );

  useEffect(() => {
    if (open) setDefaults();
    else reset();
  }, [open, setDefaults, reset]);

  return (
    <Dialog open={open} onClose={() => setOpen(false)} data-cy="switch-level-dialog">
      <DialogTitle className={classes.dialogTitle}>{title}</DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <div className={classes.resetBar}>
          <Typography>Select the level you wish to view</Typography>
          <Button variant="outlined" color="primary" onClick={() => reset()}>
            Reset
          </Button>
        </div>
        {canSetUserAccount && (
          <SelectDropdown
            name="userAccount"
            label={`Select ${userAccountList?.[0]?.level?.name || ""}`}
            value={findMatchingById(selectedParentAccount?.id, userAccountList)?.value || ""}
            data={userAccountList}
            ctrClass={classes.textField}
            onChange={onInputChange}
            multiline
          />
        )}
        {canSetParentAccount && (
          <SelectDropdown
            name="parentAccount"
            label={`Select ${parentAccountList?.[0]?.level?.name || ""}`}
            value={findMatchingById(selectedParentAccount?.id, parentAccountList)?.value || ""}
            data={parentAccountList}
            ctrClass={classes.textField}
            onChange={onInputChange}
            nextPage={fetchNextParentAccount}
            hasNextPage={hasNextParentAccount}
            searchOn={(q: any) => {
              setParentAccountQuery(q || undefined);
            }}
            searchList={parentAccountList}
            infiniteScroll
            hasSearch
            multiline
          />
        )}

        {canSetChildAccount && (
          <SelectDropdown
            name="childAccount"
            label={`Select ${childAccountList?.[0]?.level?.name || ""}`}
            value={findMatchingById(selectedChildAccount?.id, childAccountList)?.value || ""}
            data={childAccountList}
            ctrClass={classes.textField}
            onChange={onInputChange}
            nextPage={fetchNextChildAccount}
            hasNextPage={hasNextChildAccount}
            searchOn={(q: any) => setChildAccountQuery(q || undefined)}
            searchList={childAccountList}
            infiniteScroll
            hasSearch
            multiline
          />
        )}

        {canSetGrandChildAccount && (
          <SelectDropdown
            name="grandChildAccount"
            label={`Select ${grandChildAccountList?.[0]?.level?.name || ""}`}
            value={findMatchingById(selectedGrandChildAccount?.id, grandChildAccountList)?.value || ""}
            data={grandChildAccountList}
            ctrClass={classes.textField}
            onChange={onInputChange}
            nextPage={fetchNextGrandChild}
            hasNextPage={hasNextGrandChild}
            searchOn={(q: any) => setGrandChildAccountQuery(q || undefined)}
            searchList={grandChildAccountList}
            infiniteScroll
            hasSearch
            multiline
          />
        )}
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button
          onClick={handleSwitchButtonClick}
          variant="contained"
          color="primary"
          fullWidth
          data-cy="switch-level-dialog-button"
        >
          Switch
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogTitle: {
      textAlign: "center",
      backgroundColor: theme.palette.tertiary.dark,
      color: theme.palette.tertiary.contrastText,
    },
    dialogContent: {
      width: "600px",
      padding: theme.spacing(6),
      paddingBottom: theme.spacing(0),
    },
    dialogActions: {
      padding: theme.spacing(0),
      margin: theme.spacing(6),
      marginBottom: theme.spacing(4),
    },
    resetBar: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      marginBottom: "2rem",
    },
    textField: {
      marginBottom: theme.spacing(3),
    },
  }),
);

export default SwitchAccountsDialog;
