import Chip from "@mui/material/Chip";
import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Theme } from "@mui/material/styles";
import createStyles from "@mui/styles/createStyles";
import { Option } from "../../form/components/SelectDropdown";

interface Props {
  selectedAccounts: Option[];
}

const SelectedAccountsChips = ({ selectedAccounts }: Props) => {
  const classes = useStyles();

  if (selectedAccounts.length === 0) return null;

  return (
    <div className={classes.chipWrap}>
      {selectedAccounts?.map((account: any, index: number) => (
        <Chip key={account?.id || index} label={account.label} variant="outlined" className={classes.chip} />
      ))}
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    chipWrap: {
      marginTop: theme.spacing(-1),
      marginBottom: theme.spacing(3),
    },
    chip: {
      margin: theme.spacing(0.5),
    },
  }),
);

export default SelectedAccountsChips;
