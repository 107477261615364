import { CognitoUser, UserListItem } from "./types";
import { AttributeType } from "@aws-sdk/client-cognito-identity-provider/dist-types/models/models_0";
import { AccountLevelEnum } from "../account/types";
import { Option } from "../form/components/SelectDropdown";
import { UserLevelFlag } from "../user-level/types";

export const getFullName = (u: any): string => {
  let f = u?.firstName || "";
  if (u?.lastName) {
    f = f + " " + u.lastName;
  }
  return f;
};

export const getAssignedAccountsCodeList = (user: UserListItem, max?: number, join: string = ", ") => {
  return user?.accounts
    ?.map((account: any) => {
      return account?.code;
    })
    ?.filter((val, i) => val && (max ? i < max : true))
    ?.join(join);
};

export const getAssignedAccountsCodeNameList = (user: UserListItem, join: string = ", ") => {
  return user?.accounts
    ?.map((account) => {
      if (account.level === AccountLevelEnum.Asset) return account?.code;
      return [account?.code, account?.name]?.filter((val) => val)?.join(" - ");
    })
    ?.join(join);
};

export const getAccountLabel = (account: any) => {
  let label = account.code;

  if (account.level.flag === UserLevelFlag.Individual && account.tag) {
    label += ` - ${account.tag}`;
  } else if (account.level.flag !== UserLevelFlag.Individual && account.name) {
    label += ` - ${account.name}`;
  }

  return label;
};

export const getAccountOption = (account: any): Option => {
  return {
    label: getAccountLabel(account),
    value: account.id,
    id: account.id,
  };
};

const attributeMap: { [key: string]: keyof CognitoUser } = {
  "custom:user_id": "ssoId",
  given_name: "firstName",
  family_name: "lastName",
  email: "email",
  email_verified: "emailConfirmed",
};

export const mapToCognitoUser = (attributes?: AttributeType[]): CognitoUser => {
  let cognitoUser: CognitoUser = {
    ssoId: "",
    firstName: "",
    lastName: "",
    email: "",
    emailConfirmed: false,
  };

  attributes?.forEach((attr) => {
    if (attr.Name) {
      const prop = attributeMap[attr.Name];

      if (prop) {
        if (prop === "emailConfirmed") {
          cognitoUser[prop] = attr.Value === "true";
        } else {
          // @ts-ignore
          cognitoUser[prop] = attr.Value;
        }
      }
    }
  });

  return cognitoUser;
};
