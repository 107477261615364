import { useEffect } from "react";
import { useStore } from "../_app/hooks";
import { useIdentityUser } from "../auth/hooks";
import actions from "../_app/store/Actions";
import { useMyAccounts } from "../account/hooks";
import { useHubUserExist } from "../user/hooks";
import { getCachedContextHierarchy, getLastParent } from "./utils";
import { useAccountContext } from "../_app/providers/AccountHierarchyProvider";

/*
 * Context hierarchy is stored as: Grand Child -> Child -> Parent
 * The last Parent is always the group context
 * Child could be company or account context
 * Grand Child could be account or sub account context
 * lastParentId is exposed on the top level object for easy access
 * eg: {
 *   id: sub-account-id,
 *   ...data
 *   parentContext: {
 *     id: account-id
 *     ...data
 *     parentContext: {
 *       id: group-id
 *       ...data
 *     },
 *   },
 *  lastParentId: group-id
 * }
 */

export function useContextSetup() {
  const { state, dispatch } = useStore();
  const parentContextId = state.contextHierarchy?.lastParentId;
  const childContextId =
    state.contextHierarchy?.lastParentId !== state.contextHierarchy?.id ? state.contextHierarchy?.id : undefined;

  const { data: identityUser, refetch } = useIdentityUser();
  const userId = identityUser?.profile?.sub;
  const { isFetching: hubUserExistenceFetching } = useHubUserExist({ enabled: Boolean(userId) });

  const { data: groupList } = useMyAccounts({
    enabled: Boolean(userId),
    keepPreviousData: false,
    refetchOnWindowFocus: false,
  });

  const { refreshAccountHierarchy } = useAccountContext();

  const userAccount = groupList?.find((group) => group.id === parentContextId)?.userAccessibleAccounts[0];

  // Set context hierarchy
  useEffect(() => {
    refetch();
    const sessionUser = state.contextHierarchy?.userId;

    if (userId && userId !== sessionUser) {
      dispatch({
        type: actions.SET_CONTEXT_HIERARCHY,
        payload: {
          userId: userId,
        },
      });
    }

    const isGroupLevelUser = Boolean(userAccount && userAccount?.level?.id === 10);
    const groupContext = getLastParent(getCachedContextHierarchy());
    let hierarchy: any = {};

    if (!parentContextId && groupList?.length === 1) {
      hierarchy = { ...groupList?.[0] };
    }
    if (
      parentContextId &&
      !childContextId &&
      !hubUserExistenceFetching &&
      !isGroupLevelUser &&
      userAccount &&
      userAccount.id !== groupContext?.id
    ) {
      hierarchy = {
        ...userAccount,
        parentContext: groupContext,
      };
    }
    if (hierarchy?.id) {
      refreshAccountHierarchy(hierarchy.id);
      dispatch({
        type: actions.SET_CONTEXT_HIERARCHY,
        payload: {
          ...hierarchy,
          userId: userId,
          lastParentId: getLastParent(hierarchy)?.id,
        },
      });
    }
  }, [dispatch, parentContextId, childContextId, groupList, userAccount, identityUser, hubUserExistenceFetching]);

  return !hubUserExistenceFetching;
}
