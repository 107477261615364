import React from "react";
import Main from "../../_app/layouts/Main";
import { Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import { permissionCodes } from "../../permission/hooks";
import ManageBundleAlertsTabBar from "../components/BundleAlerts/ManageBundleAlertsTabBar";
import { useBundleAlertGlobal } from "../hooks";
import UILoader from "../../_app/components/UILoader";
import { UserLevelFlag } from "../../user-level/types";
import { featureFlagsMap } from "../../feature/utils";
import Card from "@mui/material/Card";
import { Alert } from "../../_app/components/UIAlert";
import { FormControlLabel } from "../../_app/components/UIFormControlLabel";
import { Switch } from "../../_app/components/UISwitch";

export const BundleAlertsGlobal = () => {
  const classes = useStyles();

  const { data: globalBundleAlertEnabled, isFetching, error } = useBundleAlertGlobal();

  return (
    <Main
      title="Global Bundle Alert"
      data-cy="support-bundle-alerts-global-page"
      featureFlag={featureFlagsMap.GLOBAL_BUNDLE_ALERTS}
      accessPermission={permissionCodes.ALERTS_ACCESS_FEATURE}
      levelFlag={UserLevelFlag.Head}
      needSelectedAccount={true}
    >
      <ManageBundleAlertsTabBar selectedTab="bundle-alerts-global">
        {isFetching ? (
          <div className={classes.loader}>
            <UILoader />
          </div>
        ) : (
          error ? (
            <Alert severity="error" className={classes.alert}>
              Cannot get Global Bundle Alert for your account.
            </Alert>
          ) : (
            <Card>
              <FormControlLabel
                data-cy="bundle-alerts-global-switch-label"
                title="Updating the Global Bundle Alert is not allowed at this moment."
                control={<Switch checked={!!globalBundleAlertEnabled} disabled={true}
                                 data-cy="bundle-alerts-global-switch" />}
                label={!!globalBundleAlertEnabled ? "Enabled" : "Disabled"}
                sx={{ ml: 1 }}
              />
            </Card>
          )
        )}
      </ManageBundleAlertsTabBar>
    </Main>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    loader: {
      display: "flex",
      width: "100%",
      justifyContent: "center",
      alignItems: "center",
      marginTop: "calc(50vh - 200px)",
    },
    actions: {
      marginBottom: theme.spacing(2),
    },
    tableCtr: {},
    alert: {
      alignItems: "center",
      marginBottom: theme.spacing(1),
    },
  })
);

export default BundleAlertsGlobal;
