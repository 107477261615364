import { Controller, useFormContext } from "react-hook-form";
import SelectDropdown, { Option } from "../../../form/components/SelectDropdown";
import React from "react";
import { useAccountsForLevel } from "../../hooks";
import { getAccountOption } from "../../utils";
import SelectedAccountsChips from "../SelectedAccountsChips";

import { AccountLevel } from "../../../account/utils";

interface Props {
  classes: any;
}

const AccountSelector = ({ classes }: Props) => {
  const {
    control,
    watch,
    formState: { errors },
  } = useFormContext();

  const [searchTerm, setSearchTerm] = React.useState<string | undefined>();

  const { data, fetchNextPage, hasNextPage, isFetching } = useAccountsForLevel(`${AccountLevel.Account.id}`, {
    "search-term": searchTerm,
  });

  const selectedAccountIds = watch("accounts", []);

  const accountsList: Option[] = data?.pages.flatMap((page) => page.list.map((account: any) => getAccountOption(account))) || [];
  const selectedAccounts = accountsList.filter((account) => selectedAccountIds.includes(account.value));

  return (
    <>
      <Controller
        name="accounts"
        control={control}
        render={({ field }) => (
          <SelectDropdown
            {...field}
            label="Accounts"
            data={accountsList}
            searchOn={(q: any) => {
              setSearchTerm(q || undefined);
            }}
            ctrClass={classes.textField}
            nextPage={fetchNextPage}
            hasNextPage={hasNextPage}
            multiline
            isFetching={isFetching}
            infiniteScroll
            multiple={true}
            hasSearch
            hasNoOptionsText
            error={!!errors?.accounts}
            helperText={errors?.accounts?.message as string | undefined}
          />
        )}
      />

      <SelectedAccountsChips selectedAccounts={selectedAccounts} />
    </>
  );
};

export default AccountSelector;
