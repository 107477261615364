import React, { useEffect, useState } from "react";
import { Alert } from "@mui/material";
import Grid from "@mui/material/Grid";
import { Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import { Permission } from "../../permission/api";
import UIConfirm from "../../_app/components/UIConfirm";
import SubmitButtons from "../../form/components/SubmitButtons";
import EditUserPermissionForm from "./EditUserPermissionForm";
import EditUserDetailsForm, { userDetailsSchema } from "../EditUserDetailsForm";
import { FormProvider, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { UserCreateUpdateRequest } from "../types";
import { simplifyPermissions } from "../../permission/utils";
import { useAccountContext } from "../../_app/providers/AccountHierarchyProvider";
import EditAccountDetailsForm from "./EditAccountDetailsForm";
import RoleSelector from "./selectors/RoleSelector";
import { AccountLevel } from "../../account/utils";

export interface Props {
  user: any;
  userId?: string;
  newUser?: boolean;
  hasEmailConfirmation?: boolean;
  isSubmitting: boolean;
  handleSubmit: (data: UserCreateUpdateRequest) => void;
  handleCancel: () => void;
  submitLabel?: string;
}

export const EditUserForm = ({
  user,
  userId,
  newUser = false,
  isSubmitting = false,
  handleSubmit = () => null,
  handleCancel = () => null,
  submitLabel = "Submit",
}: Props) => {
  const classes = useStyles();
  const [permissions, setPermissions] = useState([] as Permission[]);
  const { getAccountByLevel, currentAccountLevel, selectedAccount } = useAccountContext();

  const userFormSchema = userDetailsSchema(newUser);
  const [submitWarning, setSubmitWarning] = useState(false);

  const methods = useForm({
    defaultValues: {
      email: user?.email,
      firstName: user?.firstName,
      lastName: user?.lastName,
      userLevel: user?.userLevel || AccountLevel.Account.id,
      accounts: user?.accounts?.map((account: any) => account.id) || [],
      emailConfirm: undefined,
      parentAccount: user?.userLevel == AccountLevel.SubAccount.id ? user?.parentInvoicingAccount || "" : "",
      role: user?.role || "",
    },
    resolver: zodResolver(userFormSchema),
    mode: "onChange",
  });

  useEffect(function setDefaultAccountInfoForNewUser() {
    if (newUser && currentAccountLevel) {
      methods.setValue("userLevel", currentAccountLevel?.id);
      methods.setValue("accounts", currentAccountLevel ? [selectedAccount?.id] : []);
      if (currentAccountLevel === AccountLevel.SubAccount) {
        methods.setValue("parentAccount", getAccountByLevel(AccountLevel.Account.value)?.id);
      }
    }
  }, []);

  const hasManageContactsPermissionChecked = (permissions: any) => {
    return permissions.some((permission: any) => {
      return (
        (permission.code === "ACCOUNT_CONTACTS" && permission.value) ||
        (permission.permissions && hasManageContactsPermissionChecked(permission.permissions))
      );
    });
  };

  const onSubmit = async () => {
    await methods.trigger();
    if (methods.formState.isValid) {
      const formData = methods.getValues();
      const data: UserCreateUpdateRequest = {
        userDetails: {
          email: formData.email,
          firstName: formData.firstName,
          lastName: formData.lastName,
        },
        accountIds: formData.accounts,
        roleId: formData.role ? parseInt(formData.role) : undefined,
        permissions: simplifyPermissions(permissions),
      };

      handleSubmit(data);
    }
  };

  return (
    <>
      <Grid container className={classes.ctr} data-cy="edit-user-form">
        <FormProvider {...methods}>
          <EditUserDetailsForm classes={classes} newUser={newUser} />
          {user?.userLevel !== AccountLevel.Asset.id && <EditAccountDetailsForm classes={classes} />}
          <RoleSelector classes={classes} />
        </FormProvider>
        <EditUserPermissionForm
          userId={userId}
          roleId={methods.watch("role")}
          levelId={methods.watch("userLevel")}
          propagateData={setPermissions}
        />
      </Grid>

      <SubmitButtons
        isSubmitting={isSubmitting}
        submitLabel={submitLabel}
        onSubmit={hasManageContactsPermissionChecked(permissions) ? () => setSubmitWarning(true) : onSubmit}
        onCancel={handleCancel}
        disableSubmit={Object.keys(methods.formState.errors).length > 0}
      />
      <UIConfirm
        open={submitWarning}
        setOpen={setSubmitWarning}
        onConfirm={onSubmit}
        title="Are you sure you want to grant the Manage Contacts permission?"
      >
        <Alert severity="warning">
          Users with access to Manage Contacts can change all the authorised contacts on the account thereby taking full control
          of the account. This includes changing who is authorised to place orders and disconnect services.
        </Alert>
      </UIConfirm>
    </>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    ctr: {
      width: "100%",
    },
    card: {
      padding: theme.spacing(5),
    },
    textField: {
      marginBottom: theme.spacing(3),
    },
    noAccountNotice: {
      marginLeft: theme.spacing(1.5),
      marginBottom: theme.spacing(3),
    },
    chipWrap: {
      marginTop: theme.spacing(-1),
      marginBottom: theme.spacing(3),
    },
    chip: {
      margin: theme.spacing(0.5),
    },
  }),
);

export default EditUserForm;
