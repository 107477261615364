import React, { createContext, ReactNode, useContext, useEffect, useState } from "react";
import { Account, AccountHierarchy, AccountLevelEnum } from "../../account/types";
import { useAccountHierarchy } from "../../account/hooks";
import { AccountLevel } from "../../account/utils";
import { getCachedContextHierarchy } from "../../context/utils";

interface AccountContextType {
  refreshAccountHierarchy: (accountId: string) => void;
  getAccountByLevel: (level: AccountLevelEnum) => Account | undefined;
  currentAccountLevel: AccountLevel | undefined;
  selectedAccount: Account | undefined;
}

const ACCOUNT_CONTEXT = "ACCOUNT_CONTEXT";

const AccountContext = createContext<AccountContextType | undefined>(undefined);

export const useAccountContext = () => {
  const context = useContext(AccountContext);
  if (!context) {
    throw new Error("useAccountContext must be used within AccountProvider");
  }

  return context;
};

interface AccountProviderProps {
  children: ReactNode;
}

export const AccountHierarchyProvider = ({ children }: AccountProviderProps) => {
  const [accountId, setAccountId] = useState<string | null>(null);
  const [accountHierarchy, setAccountHierarchy] = useState<AccountHierarchy | null>(null);

  const { data } = useAccountHierarchy(accountId || "", {
    enabled: !!accountId,
  });

  useEffect(
    function loadFromQuery() {
      if (data) {
        setAccountHierarchy(data);
        localStorage.setItem(ACCOUNT_CONTEXT, JSON.stringify(data));
      }
    },
    [data],
  );

  useEffect(function loadIdFromCookie() {
    const storedAccountHierarchy = localStorage.getItem(ACCOUNT_CONTEXT);
    if (storedAccountHierarchy) {
      const hierarchy = JSON.parse(storedAccountHierarchy);
      setAccountHierarchy({
        ...hierarchy,
        // Rehydrate the level so we use the same instance and can use it in comparisons
        level: AccountLevel.fromString(hierarchy.level.value),
      });
    } else {
      syncWithLegacyContext();
    }
  }, []);

  /**
   * Syncs the new context hierarchy with the legacy context hierarchy. Legacy context will be removed after BE changes (ST-18475) are deployed.
   */
  const syncWithLegacyContext = () => {
    const storedContextHierarchy = getCachedContextHierarchy();
    if (storedContextHierarchy?.id) {
      refreshAccountHierarchy(storedContextHierarchy.id);
    }
  };

  const refreshAccountHierarchy = (accountId: string) => {
    setAccountId(accountId);
  };

  const currentAccountLevel = accountHierarchy?.level;

  const selectedAccount = accountHierarchy?.hierarchy[accountHierarchy.level.value];

  const getAccountByLevel = (level: AccountLevelEnum) => {
    return accountHierarchy?.hierarchy[level];
  };

  return (
    <AccountContext.Provider
      value={{
        selectedAccount,
        currentAccountLevel,
        refreshAccountHierarchy,
        getAccountByLevel,
      }}
    >
      {children}
    </AccountContext.Provider>
  );
};
