import api, { IdValueQuery } from "../_app/api";
import { convertFiltersToQueries } from "../filter/api";
import {
  AlertMeasure,
  BarAlertAction,
  BillingAlertBulkEdit,
  BundleAlertUpdate,
  CreateUsageAlert,
  GetBundleAlertsAccountResponse,
  GetBundleAlertsIndividualResponse,
  GetUsageAlertsHistoryResponse,
  GetUsageAlertsResponse,
  ProductType,
  UpdateUsageAlert,
  UsageAlert,
} from "./types";
import { Query } from "../filter/types";

export const getUsageAlerts = async (startingToken: string, limit: number, queries?: any[]) => {
  let { data }: { data: GetUsageAlertsResponse } = await api({
    method: "GET",
    url: "/billing-alerts",
    serialize: true,
    params: { "starting-token": startingToken, size: limit, queries },
  });
  return data;
};

export const getUsageAlertsHistory = async (startingToken: string, limit: number, queries: Query[]) => {
  let { data }: { data: GetUsageAlertsHistoryResponse } = await api({
    method: "GET",
    url: "/billing-alerts/history",
    serialize: true,
    params: { "starting-token": startingToken, size: limit, queries },
  });
  return data;
};

export const getUsageAlertById = async (id: string) => {
  const { data }: { data: UsageAlert } = await api({
    method: "GET",
    url: "/billing-alerts/" + id,
  });
  return data;
};

export const getUsageAlertsByAsset = async (assetId: string, startingToken: string, limit: number, queries?: any[]) => {
  let { data }: { data: GetUsageAlertsResponse } = await api({
    method: "GET",
    url: `/billing-alerts/assets/${assetId}`,
    serialize: true,
    params: { "starting-token": startingToken, size: limit, queries },
  });

  return data;
};

export const getProductTypes = async () => {
  const { data }: { data: ProductType[] } = await api({
    method: "GET",
    url: "/products/types",
  });

  return data;
};

export const getAlertActions = async (product: string) => {
  const { data }: { data: BarAlertAction[] } = await api({
    method: "GET",
    url: "/billing-alerts/actions",
    params: { productType: product },
  });

  return data;
};

export const getAlertMeasures = async (product: string) => {
  const { data }: { data: AlertMeasure[] } = await api({
    method: "GET",
    url: "/billing-alerts/measures",
    params: { productType: product },
  });
  const getUnit = (name: string) => {
    if (name.includes("COST")) return "£";
    if (name.includes("SMS")) return "Texts";
    if (name.includes("DURATION")) return "Minutes";
    if (name.includes("DATA")) return "MB";
    return "";
  };
  return data?.map((a) => {
    return {
      ...a,
      unit: getUnit(a.name),
    };
  });
};

export const updateUsageAlert = async (request: UpdateUsageAlert) => {
  let { data }: { data: string } = await api({
    method: "PUT",
    url: "/billing-alerts",
    serialize: true,
    data: request,
  });
  return data;
};

export const createNewAlert = async (alert: CreateUsageAlert) => {
  const parsed = convertFiltersToQueries(alert);
  const { data }: { data: UsageAlert } = await api({
    method: "POST",
    url: "/billing-alerts",
    data: parsed,
  });
  return data;
};

export const deleteAlert = async (alerts: string[]) => {
  const { data }: { data: string[] } = await api({
    method: "DELETE",
    url: "/billing-alerts",
    data: alerts,
  });

  return data;
};

export const updateBulkUsageAlerts = async (bulkUpdate: BillingAlertBulkEdit) => {
  await api({
    method: "PUT",
    url: "/billing-alerts/bulk-edit",
    serialize: true,
    data: bulkUpdate,
  });
};

/* USAGE ALERTS END */

/* BUNDLE ALERTS START */

export const getBundleAlertsIndividual = async (page: number, limit: number, queries?: IdValueQuery[]) => {
  let { data }: { data: GetBundleAlertsIndividualResponse } = await api({
    method: "GET",
    url: "/bundle-alerts/individuals",
    context_injection: true,
    serialize: true,
    params: { page: page, size: limit, queries },
  });
  return data;
};

export const getBundleAlertsAccount = async (page: number, limit: number) => {
  let { data }: { data: GetBundleAlertsAccountResponse } = await api({
    method: "GET",
    url: "/bundle-alerts/accounts",
    context_injection: true,
    params: { page: page, size: limit },
  });
  return data;
};

export const getBundleAlertGlobal = async () => {
  let { data }: { data: Boolean } = await api({
    method: "GET",
    url: "/global-bundle-alerts/enabled",
    context_injection: true,
  });
  return data;
};

export const updateBundleAlertsAccounts = async (accounts: BundleAlertUpdate[]): Promise<string> => {
  const { data }: { data: any } = await api({
    method: "PUT",
    url: `/bundle-alerts/accounts`,
    context_injection: true,
    serialize: true,
    data: accounts,
  });

  return data;
};

export const updateBundleAlertsAccountsDisableAll = async (): Promise<string> => {
  const response = await api({
    method: "PUT",
    url: `/bundle-alerts/accounts/disableAll`,
    context_injection: true,
    serialize: true,
  });
  return response.data;
};

export const updateBundleAlertsAccountsEnableAll = async (): Promise<string> => {
  const response = await api({
    method: "PUT",
    url: `/bundle-alerts/accounts/enableAll`,
    context_injection: true,
    serialize: true,
  });
  return response.data;
};

export const updateBundleAlertsIndividuals = async (individuals: BundleAlertUpdate[]): Promise<string> => {
  const { data }: { data: any } = await api({
    method: "PUT",
    url: `/bundle-alerts/individuals`,
    context_injection: true,
    serialize: true,
    data: individuals,
  });

  return data;
};

export const updateBundleAlertsIndividualsDisableAll = async (filters: IdValueQuery[] | undefined): Promise<string> => {
  const response = await api({
    method: "PUT",
    url: `/bundle-alerts/individuals/disableAll`,
    context_injection: true,
    serialize: true,
    data: convertFiltersToQueries({ filters }),
  });
  return response.data;
};

export const updateBundleAlertsindividualsEnableAll = async (filters: IdValueQuery[] | undefined): Promise<string> => {
  const response = await api({
    method: "PUT",
    url: `/bundle-alerts/individuals/enableAll`,
    context_injection: true,
    serialize: true,
    data: convertFiltersToQueries({ filters }),
  });
  return response.data;
};

export const getAllAlertsCsv = async () => {
  const response = await api({
    method: "GET",
    url: "/billing-alerts/export",
    responseType: "arraybuffer",
  });

  return response;
};
